import React, { Component } from "react";
import LazyLoad from "react-lazyload";
import "./stylesheets/_app.scss";
import HeroSection from "./components/HeroSection";
import KindFoundation from "./components/ColorSmear";
import { JetBlueContext } from "./JetBlueProvider";
import KindDesign from "./components/KindDesign";
import DayOfTravel from "./components/DayOfTravel";
import KindWords from "./components/KindWords";
import KindAtomic from "./components/KindAtomic";
import Booking from "./components/Booking";
import WebsiteSamples from "./components/WebsiteSamples";
import KindnessInAction from "./components/KindnessInAction";
import MasonrySection from "./components/MasonrySection";
import Results from "./components/Results";
import Footer from "./components/Footer";
import jbLogo from "./assets/jb-logo.png";

class App extends Component {
  constructor(props) {
    super(props);
    this.handleLoginClick = this.handleLoginClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = { isLoggedIn: false, password: null };
  }

  handleLoginClick(e) {
    if (this.state.password !== "bssdotcom") {
      return;
    }
    this.setState({ isLoggedIn: true });
  }

  handleChange({ target }) {
    this.setState({
      password: target.value
    });
  }

  render() {
    const isLoggedIn = this.state.isLoggedIn;
    let button;

    if (isLoggedIn) {
      button = (
        <div className="App">
          <HeroSection />
          <LazyLoad offset={400} once>
            <KindDesign />
          </LazyLoad>

          <LazyLoad offset={400} once>
            <KindFoundation />
          </LazyLoad>

          <KindWords />

          <LazyLoad offset={400} once>
            <KindAtomic />
          </LazyLoad>

          <LazyLoad offset={400} once>
            <Booking />
          </LazyLoad>

          <LazyLoad offset={400} once>
            <WebsiteSamples />
          </LazyLoad>

          <LazyLoad offset={400} once>
            <DayOfTravel />
          </LazyLoad>

          <LazyLoad offset={400} once>
            <KindnessInAction />
          </LazyLoad>

          <LazyLoad offset={400} once>
            <MasonrySection />
          </LazyLoad>

          <Results />
          <Footer />
        </div>
      );
    } else {
      button = (
        <div className="App jb-login">
          <div>
            <img width="200" height="auto" src={jbLogo} alt="jb-logo" />
          </div>
          <div>
            <h4>Please enter your password:</h4>
          </div>
          <div>
            <input
              type="password"
              className="passwordInput"
              onChange={this.handleChange}
            />
          </div>
          <div>
            <button onClick={this.handleLoginClick}>Sign In</button>
          </div>
        </div>
      );
    }

    return <div>{button}</div>;
  }
}

App.contextType = JetBlueContext;
export default App;
