import React, { Component } from "react";
import { JetBlueContext } from "../JetBlueProvider";
import ScrollMagic from "../components/scrollmagic"; // custom wrapper to make animation.gsap work
import { TimelineMax, Sine } from "gsap";
import video from "../assets/jb-waves.mp4";
import jbLogo from "../assets/jb-logo.png";
import BssLogo from "../assets/BigSpaceship_LOGO.svg";
import XMark from "../assets/icon_X.svg";

class HeroSection extends Component {
  constructor(props) {
    super(props);
    this.controller = new ScrollMagic.Controller();

    this.heroIntroAnimation = null;
    this.heroIntroTL = new TimelineMax({ paused: false });
  }

  componentDidMount() {
    let heroIntroHolder = document.querySelector(".hero-intro--content");

    let kindWord = document.querySelector(".kind");
    let functionalWord = document.querySelector(".functional");
    let simpleWord = document.querySelector(".simple");
    let sustainableWord = document.querySelector(".sustainable");

    let timeline = new TimelineMax({ repeat: -1 });
    timeline
      .from(kindWord, 1, { autoAlpha: 0 })
      .from(kindWord, 1, { y: -100 }, "-=1")
      .to(kindWord, 1, { y: 100 }, "+=1")
      .to(kindWord, 1, { y: 100, autoAlpha: 0 }, "-=1")
      .from(functionalWord, 1, { autoAlpha: 0 }, "-=1")
      .from(functionalWord, 1, { y: -100 }, "-=1")
      .to(functionalWord, 1, { y: 100 }, "+=1")
      .to(functionalWord, 1, { y: 100, autoAlpha: 0 }, "-=1")
      .from(simpleWord, 1, { autoAlpha: 0 }, "-=1")
      .from(simpleWord, 1, { y: -100 }, "-=1")
      .to(simpleWord, 1, { y: 100 }, "+=1")
      .to(simpleWord, 1, { y: 100, autoAlpha: 0 }, "-=1")
      .from(sustainableWord, 1, { autoAlpha: 0 }, "-=1")
      .from(sustainableWord, 1, { y: -100 }, "-=1")
      .to(sustainableWord, 1, { y: 100 }, "+=1")
      .to(sustainableWord, 1, { y: 100, autoAlpha: 0 }, "-=1");

    this.heroIntroTL.to(
      heroIntroHolder,
      6,
      { top: "-=50vh", ease: Sine.easeInOut },
      "+=0"
    );

    this.heroIntroAnimation = new ScrollMagic.Scene({
      triggerElement: ".hero-into--trigger",
      triggerHook: 0.6,
      duration: "200%" // scroll distance
    }).setTween(this.heroIntroTL);

    this.controller.addScene([this.heroIntroAnimation]);
  }

  render() {
    return (
      <header className="hero">
        <div className="content-container">
          <div className="row hero-logo">
            <div className="column small-12">
              <div className="bss-logo-container">
                <BssLogo className="hero-intro--bsslogo" />
                <XMark className="hero-intro--xmark" />
                <img className="hero-intro--logo" src={jbLogo} alt="jb-logo" />
              </div>
            </div>
          </div>

          <div className="row hero-intro">
            <div className="column small-12">
              <div className="row hero-intro--content">
                <div className="hero-into--trigger" />
                <div className="column small-12 medium-3">
                  {/* <img
                    className="hero-intro--logo"
                    src={jbLogo}
                    alt="jb-logo"
                  /> */}
                </div>

                <div className="column small-12 medium-9">
                  <div className="hero-text-container">
                    <div className="text-container">
                      <div className="make-planning-container">
                        <span>Making</span>
                        <span>travel</span>
                      </div>

                      <div className="rolodex-container">
                        <span className="word kind" id="kind">
                          kind
                        </span>
                        <span className="word functional" id="funtional">
                          functional
                        </span>
                        <span className="word simple" id="simple">
                          simple
                        </span>
                        <span className="word sustainable" id="sustainable">
                          fun
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="video-container">
          <span className="video-overlay" />
          <video
            playsInline
            autoPlay
            muted
            loop
            className="fullscreen-bg-video"
          >
            <source src={video} type="video/mp4" />
          </video>
        </div>
      </header>
    );
  }
}

HeroSection.contextType = JetBlueContext;
export default HeroSection;
