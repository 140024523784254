import React, { Component } from "react";

import { JetBlueContext } from "../JetBlueProvider";

import ScrollMagic from "../components/scrollmagic"; // custom wrapper to make animation.gsap work
import { TimelineMax, Sine } from "gsap";
import IconsKindWords from "../components/svgs/IconsKindWords";

class KindWords extends Component {
  constructor(props) {
    super(props);
    this.controller = new ScrollMagic.Controller();

    this.flyAnimation = null;

    this.flyTL = new TimelineMax({ paused: false });
    this.flyWord = null;

    this.kindWordsPin = null;
    this.kindWordsPointOne = null;
    this.kindWordsPointTwo = null;
    this.kindWordsPointThree = null;
    this.kindWordsRightContent = null;
  }

  componentDidMount() {
    let flyWithUs = document.querySelector(".kind-words--with-us");
    let iconFly = document.querySelector(".icon-fly");

    this.flyTL
      .to(iconFly, 5, { scaleX: 0.4, scaleY: 0.4, "stroke-width": 0 })
      .to(iconFly, 5, { css: { opacity: 0 }, ease: Sine.easeInOut }, "-=0")
      .to(".kind-words--point", 4, { opacity: 0 }, "-=3")
      .to(flyWithUs, 2, { css: { opacity: 1 }, ease: Sine.easeInOut }, "-=2")
      .to(".kind-words--point2", 4, { opacity: 1 }, "-=2")
      .to(
        ".fly-container",
        6,
        {
          css: { "padding-bottom": 0 },
          ease: Sine.easeInOut
        },
        "-=5"
      )
      .to(
        flyWithUs,
        5,
        { css: { transform: "translate3d(0,-100vh,0)" }, ease: Sine.easeInOut },
        "-=4"
      )
      .to(".kind-words--point2", 2, { opacity: 0 }, "-=1");

    this.flyAnimation = new ScrollMagic.Scene({
      triggerElement: ".kind-words",
      triggerHook: 0,
      duration: "120%" // scroll distance
    }).setTween(this.flyTL);

    this.kindWordsPin = new ScrollMagic.Scene({
      triggerElement: ".kind-words--pin-holder",
      triggerHook: 0,
      duration: "120%" // scroll distance
    }).setPin(".kind-words .pin", { pushFollowers: true });

    // adding via array allows to add multi scenes after all are declared.
    this.controller.addScene([this.kindWordsPin, this.flyAnimation]);
  }

  render() {
    return (
      <section className="kind-words--pin-holder">
        <IconsKindWords />
        <div className="kind-words--trigger" />
        <div className="kind-words--point-trigger-1" />

        <div className="kind-words">
          <div className="pin">
            <div className="content-container">
              <div className="row">
                <div className="column small-12 medium-4">
                  <h2 className="kind-words--title">
                    Introducing
                    <br /> kind words
                  </h2>

                  <p className="kind-words--point">
                    In the beginning, JetBlue was all caps and focused on
                    action.
                  </p>
                  <p className="kind-words--point2">
                    But we wanted to make it a little bit more conversational.
                  </p>
                </div>
                <div className="column small-12 medium-1 spacer" />
                <div className="column small-12 medium-7">
                  <div className="kind-words--content-scroll">
                    <div className="fly-container">
                      <svg className="icon icon-fly">
                        <use xlinkHref="#icon-fly" />
                      </svg>
                      <p className="kind-words--with-us">Fly with us.</p>
                    </div>

                    <div className="point-large--first-point">
                      <p className="point-large--title">Let’s go somewhere.</p>
                      <p className="point-large--desc">
                        Love at first flight. JetBlue offers flights to 90+
                        destinations with free inflight entertainment, free
                        brand-name snacks and drinks, lots of legroom and
                        award-winning service.
                      </p>
                    </div>
                    <div className="point-large">
                      <p className="point-large--title">
                        Vacay <br />
                        your way.
                      </p>
                      <p className="point-large--desc">
                        Adventure trips, girlfriend getaways, family resorts,
                        romantic escapes—we go there. Build your perfect getaway
                        and save when you book a flight and hotel together.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

KindWords.contextType = JetBlueContext;
export default KindWords;
