import React from "react";

const IconsKindWords = () => (
  <svg
    aria-hidden="true"
    style={{ position: "absolute", width: 0, height: 0, overflow: "hidden" }}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <symbol id="icon-fly" viewBox="0 0 62 32">
        <title>fly</title>
        <path d="M19.87 3.245h-16.342v11.699h13.931v3.036h-13.931v14.020h-3.393v-31.792h19.736v3.036zM43.531 32h-19.646v-31.792h3.393v28.755h16.253v3.036zM61.746 0.208l-9.377 18.664v13.127h-3.394v-13.127l-9.466-18.664h3.661l7.501 15.226 7.412-15.226h3.661z"></path>
      </symbol>
    </defs>
  </svg>
);

export default IconsKindWords;
