import React, { Component } from "react";
import { JetBlueContext } from "../JetBlueProvider";
import ScrollMagic from "../components/scrollmagic"; // custom wrapper to make animation.gsap work
import { TimelineMax, Sine } from "gsap";
import { isBrowser } from "react-device-detect";

import carouselOne from "../assets/carousel_one.gif";
import carouselTwo from "../assets/carousel_two.gif";
import carouselThree from "../assets/carousel_three.gif";
import carouselFour from "../assets/carousel_four.mp4";
import carouselFive from "../assets/carousel_five.mp4";
import carouselSix from "../assets/carousel_six.mp4";
import carouselSeven from "../assets/carousel_seven.gif";

class KindnessInAction extends Component {
  constructor(props) {
    super(props);
    this.controller = new ScrollMagic.Controller();

    this.kindnessActionAnimation = null;

    this.kindnessActionTL = new TimelineMax({ paused: false });
  }

  componentDidMount() {
    this.kindnessActionTL.to(
      ".carousel-holder",
      6,
      {
        css: {
          transform: isBrowser ? "translateX(-65%)" : "translateX(-2450px)"
        },
        ease: Sine.easeInOut
      },
      "-=0"
    );

    this.kindnessActionAnimation = new ScrollMagic.Scene({
      triggerElement: ".kindness-in-action--trigger",
      triggerHook: 0,
      duration: "200%" // scroll distance
    })
      .setTween(this.kindnessActionTL)
      .setPin(".kindness-in-action--pin");

    // adding via array allows to add multi scenes after all are declared.
    this.controller.addScene([this.kindnessActionAnimation]);
  }

  render() {
    return (
      <section className="kindness-in-action">
        <div className="kindness-in-action--trigger" />
        <div className="kindness-in-action--pin">
          <div className="content-container">
            <div className="row kindness-in-action--wrapper">
              <div className="column small-12 medium-1 spacer" />
              <div className="column small-12 medium-4">
                <h2 className="kindness-in-action--header">
                  Kindness <br />
                  in Social
                </h2>
              </div>
              <div className="column small-12 medium-1 spacer" />
              <div className="column small-12 medium-5">
                <p className="kindness-in-action--desc">
                  To ease the transition from social to commerce we infused our
                  social content with the same principles of kindness.
                </p>
              </div>
              <div className="column small-12 medium-1 spacer" />
            </div>

            <div className="row kindness-in-action--carousel">
              <div className="column small-12">
                <div className="carousel-holder">
                  <div className="carousel-item one">
                    <img
                      className="carousel-item--media"
                      src={carouselOne}
                      alt="jb-text"
                    />
                  </div>
                  <div className="carousel-item two">
                    <img
                      className="carousel-item--media"
                      src={carouselTwo}
                      alt="jb-text"
                    />
                  </div>
                  <div className="carousel-item three">
                    <img
                      className="carousel-item--media"
                      src={carouselThree}
                      alt="jb-text"
                    />
                  </div>
                  <div className="carousel-item four">
                    <video className="carousel-item--media" muted autoPlay loop>
                      <source src={carouselFour} type="video/mp4" />
                    </video>
                  </div>
                  <div className="carousel-item five">
                    <video className="carousel-item--media" muted autoPlay loop>
                      <source src={carouselFive} type="video/mp4" />
                    </video>
                  </div>
                  <div className="carousel-item six">
                    <video className="carousel-item--media" muted autoPlay loop>
                      <source src={carouselSix} type="video/mp4" />
                    </video>
                  </div>
                  <div className="carousel-item seven">
                    <img
                      className="carousel-item--media"
                      src={carouselSeven}
                      alt="jb-text"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

KindnessInAction.contextType = JetBlueContext;
export default KindnessInAction;
