import React, { Component } from 'react';

/* First we will make a new context */
export const JetBlueContext = React.createContext({
  currentDay: new Date().getDate()
})

/* Then create a provider Component */
class JetBlueProvider extends Component {
  state = {
    day: new Date().getDate(),
    offsetY: 0
  }

  componentDidMount() {
    window.addEventListener('scroll', () => {
      this.setState({
        offsetY: window.pageYOffset
      })
      // this.scrollAnimation(animDuration, this.anim)
    })
  }

  render() {
    return (
      <JetBlueContext.Provider
        value={this.state}
      >
        {this.props.children}
      </JetBlueContext.Provider>
    )
  }
}

export default JetBlueProvider;
/* then make a consumer which will surface it */