import React, { Component } from "react";
import { JetBlueContext } from "../JetBlueProvider";

import ScrollMagic from "../components/scrollmagic"; // custom wrapper to make animation.gsap work
import { TimelineMax, SteppedEase } from "gsap";

import heroImage from "../assets/plane-interior.png";

import animation from "./animations/data/JB_CaseStudyAnimation.json";
import bodymovin from "lottie-web";

let heroStyleBg = {
  backgroundImage: `url(${heroImage})`
};

class KindAtomic extends Component {
  constructor(props) {
    super(props);
    this.controller = new ScrollMagic.Controller();

    this.atomicAnimation = null;

    this.shadeTL = new TimelineMax({ paused: false });
    this.atomicTL = new TimelineMax({
      paused: false,
      repeat: -1,
      repeatDelay: -1
    });
  }

  componentDidMount() {
    let shade = document.querySelector(".kind-atomic--shade");
    let element = document.querySelector(".kind-atomic--sprite-holder");

    // bodymoving animation
    // ===========================
    bodymovin.loadAnimation({
      container: element,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animation
    });

    this.shadeTL.to(
      shade,
      7,
      { opacity: "0.6", ease: SteppedEase.config(151) },
      "+=0"
    );

    this.ShadeAnimation = new ScrollMagic.Scene({
      triggerElement: ".kind-atomic",
      triggerHook: "onEnter",
      duration: "50%", // scroll distance
      offset: 400
    }).setTween(this.shadeTL);

    this.SpriteAnimation = new ScrollMagic.Scene({
      triggerElement: ".kind-atomic",
      triggerHook: "onCenter",
      duration: "100%" // scroll distance
    }).setTween(this.atomicTL);

    this.controller.addScene([this.ShadeAnimation, this.SpriteAnimation]);
  }

  render() {
    return (
      <section className="kind-atomic">
        <div className="kind-atomic--hero" style={heroStyleBg}>
          <div className="kind-atomic--shade" />
          <div className="kind-atomic--sprite-holder" />
        </div>
        <div className="content-container kind-atomic--notch">
          <div className="row">
            <div className="column small-12 medium-1 spacer" />
            <div className="column small-12 medium-3">
              <h2 className="kind-atomic--title">Kind is Responsive</h2>
            </div>
            <div className="column small-12 medium-2 spacer" />
            <div className="column small-12 medium-5">
              <p className="kind-atomic--desc">
                After streamlining the system, the number of digital elements
                was reduced from over 150 down to just 40 — making it easier
                than ever for customers to navigate and enjoy a unified
                experience across all devices.
              </p>
            </div>
            <div className="column small-12 medium-1 spacer" />
          </div>
        </div>
      </section>
    );
  }
}

KindAtomic.contextType = JetBlueContext;
export default KindAtomic;
