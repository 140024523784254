import React, { Component } from "react";
import { JetBlueContext } from "../JetBlueProvider";
import ScrollMagic from "../components/scrollmagic"; // custom wrapper to make animation.gsap work
import { TimelineMax } from "gsap";

import websiteHomepage from "../assets/JB_Concept_Homepage.jpg";
import websiteHomepageNav from "../assets/Regular_Nav.png";

import recommendedMobile from "../assets/recommended_mobile.png";
import bookingSchedule from "../assets/booking_schedule.png";

class Booking extends Component {
  constructor(props) {
    super(props);
    this.controller = new ScrollMagic.Controller();
    this.deskAnimation = null;
    this.deskTL = new TimelineMax({ paused: false });
  }

  render() {
    return (
      <section className="booking">
        <div className="booking--trigger1" />
        <div className="booking-desktop">
          <div className="booking-desktop--cover" />
          <div className="content-container">
            <div className="row">
              <div className="column small-12 medium-1 spacer" />
              <div className="column small-12 medium-10 shorterColumn">
                <div className="booking-homepage-sticky-nav">
                  <img
                    className="sticky-nav"
                    src={websiteHomepageNav}
                    alt="sticky nav"
                  />
                </div>
                <img
                  className="booking-homepage"
                  src={websiteHomepage}
                  alt="homepage example"
                />
              </div>
              <div className="column small-12 medium-1 spacer" />
            </div>
          </div>
        </div>

        <div className="booking-mobile">
          <div className="content-container">
            <div className="row">
              <div className="column small-12 medium-6">
                <div className="booking-mobile--holder">
                  <img
                    className="booking-image"
                    src={recommendedMobile}
                    alt="homepage example"
                  />
                  <p className="booking-mobile--copy">
                    <span>Best Fare Finder</span>
                    To help drive conversion we streamlined the fare finder to
                    truly help customers make the best choices in flights for
                    every destination.
                  </p>
                </div>
              </div>
              <div className="column small-12 medium-6">
                <div className="booking-mobile--holder right">
                  <img
                    className="booking-image"
                    src={bookingSchedule}
                    alt="homepage example"
                  />
                  <p className="booking-mobile--copy">
                    <span>Date Selector</span>
                    Choosing a two-color pattern in travel dates helped
                    customers see exactly how many travel and non-travel days
                    they had.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

Booking.contextType = JetBlueContext;
export default Booking;
