import React, { Component } from "react";
import { JetBlueContext } from "../JetBlueProvider";
import ScrollMagic from "../components/scrollmagic"; // custom wrapper to make animation.gsap work
import { TimelineMax, Sine } from "gsap";
import jbPatterns from "../assets/jetBlue_patterns.mp4";
import jbPatternsMobile from "../assets/jetBlue_Patterns_Mobile.mp4";
import { BrowserView, MobileView, isBrowser } from "react-device-detect";

class KindDesign extends Component {
  constructor(props) {
    super(props);
    this.controller = new ScrollMagic.Controller();

    this.kindDesignAnimation = null;
    this.kindDesignTL = new TimelineMax({ paused: false });
    this.kindDesignPatternAnimation = null;
    this.kindDesignPatternTL = new TimelineMax({ paused: false });
    this.kindDesignStartedAnimation = null;
    this.kindDesignStartedTL = new TimelineMax({ paused: false });
    this.kindDesigntitleTL = new TimelineMax({ paused: false });
  }

  componentDidMount() {
    let kindDesignHolder = document.querySelector(".kind-design");
    let kindDesignPatternHolder = document.querySelector(
      ".kind-deising-pattern"
    );
    let kindDesignTitle = document.querySelector(".kind-design--title");

    this.kindDesignTL.from(
      kindDesignHolder,
      6,
      { autoAlpha: 0, top: "+=25vh", ease: Sine.easeInOut },
      "+=0"
    );

    this.kindDesignPatternTL.from(
      kindDesignPatternHolder,
      6,
      { autoAlpha: 0, top: "+=25vh", ease: Sine.easeInOut },
      "+=0"
    );

    this.kindDesigntitleTL
      .to(
        kindDesignTitle,
        6,
        { scale: 0.25, top: "+=25vh", ease: Sine.easeInOut },
        "+=0"
      )
      .to(kindDesignTitle, 1, { autoAlpha: 0, ease: Sine.easeInOut }, "-=2");

    this.kindDesignAnimation = new ScrollMagic.Scene({
      triggerElement: ".kind-design--trigger",
      triggerHook: 1,
      duration: "100%" // scroll distance
    }).setTween(this.kindDesignTL);

    this.kindDesignPatternAnimation = new ScrollMagic.Scene({
      triggerElement: ".kind-design-pattern--trigger",
      triggerHook: 1,
      duration: "100%" // scroll distance
    }).setTween(this.kindDesignPatternTL);

    this.kindDesignStartedAnimation = new ScrollMagic.Scene({
      triggerElement: ".kind-design--getting-started--trigger",
      triggerHook: 1,
      duration: "100%" // scroll distance
    }).setTween(this.kindDesignStartedTL);

    this.kindDesignTitleAnimation = new ScrollMagic.Scene({
      triggerElement: ".kind-design--titletrigger",
      triggerHook: 1,
      duration: "100%" // scroll distance
    }).setTween(this.kindDesigntitleTL);

    this.controller.addScene([
      this.kindDesignAnimation,
      this.kindDesignPatternAnimation,
      isBrowser ? this.kindDesignStartedAnimation : null,
      isBrowser ? this.kindDesignTitleAnimation : null
    ]);
  }

  render() {
    return (
      <>
        <section className="kind-design">
          <div className="kind-design--trigger" />
          <div className="kind-design--titletrigger" />
          <div className="content-container">
            <div className="row">
              <div className="column small-12 medium-4">
                <h2 className="kind-design--title">Kind design</h2>
              </div>
              <div className="column small-12 medium-3 spacer" />
              <div className="column small-12 medium-5">
                <p className="kind-design--copy">
                  Since the first plane hit the runway, JetBlue has always
                  aspired to be the most caring travel provider in the world.
                  With that goal in mind, we set out to evolve their digital
                  ecosystem and provide travelers with a more caring and
                  thoughtful approach to travel.
                </p>
                <a
                  className="kind-design--cta"
                  href="www.jetblue.com"
                  target="_blank"
                >
                  View Website
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="kind-deising-pattern">
          <div className="kind-design-pattern--trigger" />
          <BrowserView>
            <div className="kind-design--video-wrapper">
              <video
                playsInline
                autoPlay
                muted
                loop
                className="kind-design--video"
              >
                <source src={jbPatterns} type="video/mp4" />
              </video>
            </div>
          </BrowserView>
          <MobileView>
            <div className="kind-design--mobile-wrapper">
              <video
                playsInline
                autoPlay
                muted
                loop
                className="kind-design--video"
              >
                <source src={jbPatternsMobile} type="video/mp4" />
              </video>
            </div>
          </MobileView>
        </section>
        <section className="kind-design--getting-started">
          <div className="content-container">
            <div className="row">
              <div className="column small-1 spacer" />
              <div className="column small-12 medium-2">
                <h3 className="kind-design--getting-started--title">
                  Getting Started
                </h3>
              </div>
              <div className="column small-12 medium-4">
                <p>
                  We started with the core of the JetBlue DNA  by taking
                  inspiration from the JetBlue logo. Curves and type accents
                  influenced circular forms and informed the basis of graphic
                  patterns.
                </p>
              </div>
              <div className="column small-12 medium-4">
                <p>
                  The patterns add a sense of calm and timelessness to the brand
                  while tapping into the joy and nostalgia of the golden age of
                  air travel.
                </p>
              </div>
              <div className="column small-1 spacer" />
            </div>
          </div>
        </section>
      </>
    );
  }
}

KindDesign.contextType = JetBlueContext;
export default KindDesign;
