import React, { Component } from "react";
import { JetBlueContext } from "../JetBlueProvider";

import ScrollMagic from "../components/scrollmagic"; // custom wrapper to make animation.gsap work
import { TweenMax, TimelineMax, Sine, Linear, Back } from "gsap";

import Plane from "../assets/jetBlue_Plane.svg";
import animation from "./animations/data/suitcase.json";
import bodymovin from "lottie-web";

//icons
import IconPartnerAirline from "../assets/icon_Partner_Airline.svg";
import IconFlight from "../assets/icon_Flight.svg";
import IconFlightHotels from "../assets/icon_Flights_Hotels.svg";
import IconFlying from "../assets/icon_Flying.svg";
import IconCar from "../assets/icon_Car.svg";
import IconHome from "../assets/icon_Home.svg";
import IconHotel from "../assets/icon_Hotel.svg";
import IconApp from "../assets/icon_App.svg";
import IconRewards from "../assets/icon_Rewards.svg";

import IconCard from "../assets/icon_Card.svg";
import IconFinancial from "../assets/icon_Financial.svg";
import IconCashPlus from "../assets/icon_Cash_Plus_Points.svg";
import IconShopping from "../assets/icon_Shopping.svg";
import IconFilter from "../assets/icon_Filter.svg";
import IconPartners from "../assets/icon_Partners.svg";
import IconEmail from "../assets/icon_Email.svg";
import IconSave from "../assets/icon_Save.svg";
import IconClaimsBook from "../assets/icon_Claims_Book.svg";

import IconSofly from "../assets/icon_Sofly.svg";
import IconBubble from "../assets/icon_Bubble.svg";
import IconFlyFi from "../assets/icon_Fly-Fi.svg";
import IconTravelingTogether from "../assets/icon_Traveling_Together.svg";
import IconBaggage from "../assets/icon_Baggage.svg";
import IconPooling from "../assets/icon_Pooling.svg";
import IconBicycle from "../assets/icon_Bicycle.svg";
import IconTrash from "../assets/icon_Trash.svg";
import IconDeals from "../assets/icon_Deals.svg";

import IconGift from "../assets/icon_Gift.svg";
import IconTurkey from "../assets/icon_Turkey.svg";
import IconCandles from "../assets/icon_Candles.svg";
import IconSee from "../assets/icon_See.svg";
import IconEat from "../assets/icon_Eat.svg";
import IconMint from "../assets/icon_Mint.svg";
import IconArrows from "../assets/icon_Arrows.svg";
import IconBadges from "../assets/icon_Badges.svg";
import IconCrop from "../assets/icon_Crop.svg";

import IconDestinations from "../assets/icon_Destinations.svg";
import IconQuestion from "../assets/icon_Question.svg";
import IconStar from "../assets/icon_Star.svg";
import IconClose from "../assets/icon_Close.svg";
import IconMap from "../assets/icon_Map.svg";
import IconRedemption from "../assets/icon_Redemption.svg";
import IconAccruel from "../assets/icon_Accruel.svg";
import IconAlert from "../assets/icon_Alert.svg";
import IconCustomerService from "../assets/icon_Customer_Service.svg";

class ColorSmear extends Component {
  constructor(props) {
    super(props);
    this.controller = new ScrollMagic.Controller();

    this.smearTL = new TimelineMax({ paused: false });
    this.planeTL = new TimelineMax({ paused: false });
    this.luggageTL = new TimelineMax({ paused: false });
    this.circlesTL = new TimelineMax({ paused: false });
    this.smearAnimation = null;
    this.smearPin = null;
    this.smearPlane = null;

    this.kindFoundationAnimation = null;
    this.kindFoundationTL = new TimelineMax({ paused: false });

    this.smearCircle1 = null;
    this.smearMidCircle1 = null;
    this.smearBotCircle1 = null;

    this.smearCircle2 = null;
    this.smearMidCircle2 = null;
    this.smearBotCircle2 = null;

    this.smearCircle3 = null;
    this.smearMidCircle3 = null;
    this.smearBotCircle3 = null;

    this.smearCircle4 = null;
    this.smearMidCircle4 = null;
    this.smearBotCircle4 = null;

    this.smearMidCircle5 = null;
    this.smearBotCircle5 = null;

    this.smearCircle6 = null;
    this.smearMidCircle6 = null;
    this.smearBotCircle6 = null;

    this.smearCircle7 = null;
    this.smearMidCircle7 = null;
    this.smearBotCircle7 = null;

    this.smearCircle8 = null;
    this.smearMidCircle8 = null;
    this.smearBotCircle8 = null;

    this.smearCircle9 = null;
    this.smearMidCircle9 = null;
    this.smearBotCircle9 = null;
  }

  componentDidMount() {
    let smearHolder = document.querySelector(".smear-animation-holder");
    let smearIntro = document.querySelector(".circle-5--intro");
    let smearOutro = document.querySelector(".circle-5--outro");
    let smearCircles = [...document.querySelectorAll(".circle-smear")];
    let smearPlane = document.querySelector(".jb--plane");
    let element = document.querySelector(".luggage-frames");
    let smearLuggage = document.querySelector(".luggage-holder");
    let smearIconsCopy = document.querySelector(".luggage-icon-copy");
    let smearIcons = [
      ".partners",
      ".pooling",
      ".filter",
      ".shopping",
      ".travelingtogether",
      ".see",
      ".eat",
      ".mint",
      ".arrow",
      ".email",
      ".bicycle",
      ".hotel",
      ".home",
      ".car",
      ".flying",
      ".flighthotels",
      ".cashplus",
      ".flyfi",
      ".candles",
      ".start",
      ".close",
      ".map",
      ".redemption",
      ".accruel",
      ".alert",
      ".badges",
      ".save",
      ".trash",
      ".app",
      ".flight",
      ".financial",
      ".bubble",
      ".turkey",
      ".question",
      ".customerservice",
      ".crop",
      ".deals",
      ".claimsbook",
      ".rewards",
      ".partnerairline",
      ".sofly",
      ".card",
      ".gift",
      ".destinations"
    ];

    let kindFoundationHolder = document.querySelector(".kind-foundation");

    // bodymoving animation
    // ===========================
    let animData = {
      container: element,
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: animation
    };

    let anim = bodymovin.loadAnimation(animData);

    let circleTrack = function() {
      let readyToSmear = smearIntro.parentNode.classList.contains(
        "circleToSmear"
      );
      smearCircles.forEach(circle => {
        if (!readyToSmear) {
          circle.classList.add("circleToSmear");
        } else {
          circle.classList.remove("circleToSmear");
        }
      });
    };

    // SECTION TITLE ANIMATION
    this.kindFoundationTL.from(
      kindFoundationHolder,
      6,
      { autoAlpha: 0, top: "+=0vh", ease: Linear.easeNone },
      "+=0"
    );

    // CIRCLE ANIMATION
    // ==============================

    TweenMax.set(this.smearCircle5, { transformOrigin: "center center" });
    this.circlesTL
      // dots appear from mid dot
      .to(smearIntro, 1, { autoAlpha: 0 }, "-=0")
      .from(this.smearCircle5, 1, { scale: 0.4 }, "-=0")
      .staggerFrom(
        [
          this.smearCircle4,
          this.smearCircle3,
          this.smearCircle2,
          this.smearCircle1
        ],
        1,
        { opacity: 0, left: "100%", ease: Linear.easeNone },
        0.5,
        "-=0"
      )
      .staggerFrom(
        [
          this.smearCircle6,
          this.smearCircle7,
          this.smearCircle8,
          this.smearCircle9
        ],
        1,
        { opacity: 0, left: "-100%", ease: Linear.easeNone },
        0.5,
        "-=3"
      )
      .to(smearOutro, 1, { autoAlpha: 1 }, "-=0")
      .addCallback(circleTrack, "+=0")
      .to(smearHolder, 6, { top: "-=25vh", ease: Linear.easeNone }, "-=1")
      // dots smear
      .to(
        [this.smearMidCircle1, this.smearMidCircle9],
        6,
        { height: "43%", ease: Linear.easeNone },
        "+=0"
      )
      .to(
        [this.smearMidCircle2, this.smearMidCircle8],
        6,
        { height: "55%", ease: Linear.easeNone },
        "-=6"
      )
      .to(
        [this.smearMidCircle3, this.smearMidCircle7],
        6,
        { height: "65%", ease: Linear.easeNone },
        "-=6"
      )
      .to(
        [this.smearMidCircle4, this.smearMidCircle6],
        6,
        { height: "73%", ease: Linear.easeNone },
        "-=6"
      )
      .to(
        this.smearMidCircle5,
        6,
        { height: "80%", ease: Linear.easeNone },
        "-=6"
      )

      .to(smearHolder, 8, { top: "-=95vh", ease: Linear.easeNone }, "-=0");

    // LUGGAGE FRAME ANIMATION
    // ==============================
    //anim.totalFrames-1 total frames
    this.luggageTL
      .to(".luggage-container", 2, { top: "147vh" })
      .to(
        { frame: 0 },
        20,
        {
          frame: 245,
          onUpdate: function() {
            anim.goToAndStop(Math.round(this.target.frame), true);
          },
          ease: Linear.easeNone
        },
        "-=2"
      )
      // .to(smearLuggage, 8, { top: '-=120vh', ease: Linear.easeNone }, '-=5')
      .to(smearLuggage, 0, { autoAlpha: 0 }, "-=16.5")
      .to(smearLuggage, 1, { autoAlpha: 1 }, "-=0")
      .to(element, 1, { autoAlpha: 0 }, "-=0.5")
      .staggerFrom(
        smearIcons,
        2,
        { scale: 0.25, autoAlpha: 0, ease: Linear.easeNone },
        0.15,
        "-=3.5"
      )
      .from(smearIconsCopy, 4, { autoAlpha: 0, ease: Linear.easeNone }, "-=3");

    // PLANE ANIMATION
    // ==============================

    TweenMax.set(smearPlane, { transformOrigin: "center center" });
    this.planeTL
      .timeScale(0.3)
      .to(smearPlane, 4, { top: "+=117vh" }, "+=0")
      .to(
        smearPlane,
        0.25,
        { autoAlpha: 1, transform: "translate(-50%, 100%)" },
        "-=4"
      )
      .to(
        smearPlane,
        1.5,
        {
          transform: "translate(-600%, 100%)",
          repeat: 1,
          yoyo: true,
          ease: Linear.easeNone
        },
        "-=3"
      )
      .to(smearPlane, 0.75, { rotation: 35, repeat: 1, yoyo: true }, "-=3")
      .to(smearPlane, 1.5, { rotation: -90 }, "-=1.5")
      .to(smearPlane, 4, { left: "+=100vh" }, "+=0")
      .to(smearPlane, 4, { top: "-=65vh", ease: Linear.easeNone }, "-=4")
      .to(smearPlane, 3.5, { rotation: -130, ease: Linear.easeNone }, "-=4");

    // MASTER ANIMATION
    // ==============================
    this.smearTL
      .add(this.circlesTL, "-=0")
      .add(this.planeTL, "-=10")
      .add(this.luggageTL, "-=17");

    this.kindFoundationAnimation = new ScrollMagic.Scene({
      triggerElement: ".kind-foundation--trigger",
      triggerHook: 1,
      duration: "100%" // scroll distance
    }).setTween(this.kindFoundationTL);

    this.smearPin = new ScrollMagic.Scene({
      triggerElement: ".smear-animation",
      triggerHook: 0.25,
      duration: "400%" // scroll distance
    }).setPin(".smear-animation");

    this.smearAnimation = new ScrollMagic.Scene({
      triggerElement: ".kind-foundation",
      triggerHook: 0,
      duration: "400%" // scroll distance
    }).setTween(this.smearTL);

    // adding via array allows to add multi scenes after all are declared.
    this.controller.addScene([
      this.smearPin,
      this.smearAnimation,
      this.kindFoundationAnimation
      //this.smearPlane
    ]);
  }

  render() {
    return (
      <>
        <section className="kind-foundation">
          <div className="content-container">
            <div className="row">
              <div className="kind-foundation--trigger" />
              <div className="column small-12 medium-1 spacer" />
              <div className="column small-12 medium-5">
                <h2 className="kind-foundation--title">A kind foundation</h2>
                <p className="kind-foundation--intro">
                  Defining a system with color, typography, iconography, and
                  kind interface decisions to lower anxiety, raise expectations
                  and bring back the joy of travel.
                </p>
              </div>
              <div className="column small-12 medium-6 spacer" />
            </div>
          </div>
        </section>
        <section className="kind-foundation-animation">
          <div className="content-container">
            <div className="row">
              <div className="column small-12">
                <div className="smear-animation">
                  <div className="smear-animation-holder">
                    <Plane className="jb--plane" width="78" height="82" />

                    <div className="circle-smear circle-1">
                      <div
                        className="circle"
                        ref={div => (this.smearCircle1 = div)}
                      >
                        <div className="circle-smear--top" />
                        <div
                          className="circle-smear--mid"
                          ref={div => (this.smearMidCircle1 = div)}
                        />
                        <div
                          className="circle-smear--bottom"
                          ref={div => (this.smearBotCircle1 = div)}
                        />
                      </div>
                    </div>

                    <div className="circle-smear circle-2">
                      <div
                        className="circle"
                        ref={div => (this.smearCircle2 = div)}
                      >
                        <div className="circle-smear--top" />
                        <div
                          className="circle-smear--mid"
                          ref={div => (this.smearMidCircle2 = div)}
                        />
                        <div
                          className="circle-smear--bottom"
                          ref={div => (this.smearBotCircle2 = div)}
                        />
                      </div>
                    </div>

                    <div className="circle-smear circle-3">
                      <div
                        className="circle"
                        ref={div => (this.smearCircle3 = div)}
                      >
                        <div className="circle-smear--top" />
                        <div
                          className="circle-smear--mid"
                          ref={div => (this.smearMidCircle3 = div)}
                        />
                        <div
                          className="circle-smear--bottom"
                          ref={div => (this.smearBotCircle3 = div)}
                        />
                      </div>
                    </div>

                    <div className="circle-smear circle-4">
                      <div
                        className="circle"
                        ref={div => (this.smearCircle4 = div)}
                      >
                        <div className="circle-smear--top" />
                        <div
                          className="circle-smear--mid"
                          ref={div => (this.smearMidCircle4 = div)}
                        />
                        <div
                          className="circle-smear--bottom"
                          ref={div => (this.smearBotCircle4 = div)}
                        />
                      </div>
                    </div>

                    <div className="circle-smear circle-5">
                      <p className="circle-5--intro">
                        From a single color being the core of JetBlue’s
                        identity.
                      </p>
                      <p className="circle-5--outro">
                        To a family of color that express the playful spirit of
                        JetBlue across all brand messaging.
                      </p>
                      <div
                        className="circle"
                        ref={div => (this.smearCircle5 = div)}
                      >
                        <div className="circle-smear--top" />
                        <div
                          className="circle-smear--mid"
                          ref={div => (this.smearMidCircle5 = div)}
                        >
                          <div className="plane--marker" />
                        </div>
                        <div
                          className="circle-smear--bottom"
                          ref={div => (this.smearBotCircle5 = div)}
                        />
                      </div>
                    </div>

                    <div className="circle-smear circle-6">
                      <div
                        className="circle"
                        ref={div => (this.smearCircle6 = div)}
                      >
                        <div className="circle-smear--top" />
                        <div
                          className="circle-smear--mid"
                          ref={div => (this.smearMidCircle6 = div)}
                        />
                        <div
                          className="circle-smear--bottom"
                          ref={div => (this.smearBotCircle6 = div)}
                        />
                      </div>
                    </div>

                    <div className="circle-smear circle-7">
                      <div
                        className="circle"
                        ref={div => (this.smearCircle7 = div)}
                      >
                        <div className="circle-smear--top" />
                        <div
                          className="circle-smear--mid"
                          ref={div => (this.smearMidCircle7 = div)}
                        />
                        <div
                          className="circle-smear--bottom"
                          ref={div => (this.smearBotCircle7 = div)}
                        />
                      </div>
                    </div>

                    <div className="circle-smear circle-8">
                      <div
                        className="circle"
                        ref={div => (this.smearCircle8 = div)}
                      >
                        <div className="circle-smear--top" />
                        <div
                          className="circle-smear--mid"
                          ref={div => (this.smearMidCircle8 = div)}
                        />
                        <div
                          className="circle-smear--bottom"
                          ref={div => (this.smearBotCircle8 = div)}
                        />
                      </div>
                    </div>

                    <div className="circle-smear circle-9">
                      <div
                        className="circle"
                        ref={div => (this.smearCircle9 = div)}
                      >
                        <div className="circle-smear--top" />
                        <div
                          className="circle-smear--mid"
                          ref={div => (this.smearMidCircle9 = div)}
                        />
                        <div
                          className="circle-smear--bottom"
                          ref={div => (this.smearBotCircle9 = div)}
                        />
                      </div>
                    </div>
                    <div className="luggage-container">
                      <div className="luggage-frames" />
                    </div>
                    <div className="luggage-holder">
                      {/* Line one */}
                      <div className="luggage-icons-icon partnerairline">
                        <IconPartnerAirline />
                      </div>
                      <div className="luggage-icons-icon flight">
                        <IconFlight />
                      </div>
                      <div className="luggage-icons-icon flighthotels">
                        <IconFlightHotels />
                      </div>
                      <div className="luggage-icons-icon flying">
                        <IconFlying />
                      </div>
                      <div className="luggage-icons-icon car">
                        <IconCar />
                      </div>
                      <div className="luggage-icons-icon home">
                        <IconHome />
                      </div>
                      <div className="luggage-icons-icon hotel">
                        <IconHotel />
                      </div>
                      <div className="luggage-icons-icon app">
                        <IconApp />
                      </div>
                      <div className="luggage-icons-icon rewards">
                        <IconRewards />
                      </div>

                      {/* Line two */}
                      <div className="luggage-icons-icon sofly">
                        <IconSofly />
                      </div>
                      <div className="luggage-icons-icon financial">
                        <IconFinancial />
                      </div>
                      <div className="luggage-icons-icon cashplus">
                        <IconCashPlus />
                      </div>
                      <div className="luggage-icons-icon shopping">
                        <IconShopping />
                      </div>
                      <div className="luggage-icons-icon filter">
                        <IconFilter />
                      </div>
                      <div className="luggage-icons-icon pooling">
                        <IconPooling />
                      </div>
                      <div className="luggage-icons-icon bicycle">
                        <IconBicycle />
                      </div>
                      <div className="luggage-icons-icon trash">
                        <IconTrash />
                      </div>
                      <div className="luggage-icons-icon claimsbook">
                        <IconClaimsBook />
                      </div>

                      {/* Line three */}
                      <div className="luggage-icons-icon card">
                        <IconCard />
                      </div>
                      <div className="luggage-icons-icon bubble">
                        <IconBubble />
                      </div>
                      <div className="luggage-icons-icon flyfi">
                        <IconFlyFi />
                      </div>
                      <div className="luggage-icons-icon travelingtogether">
                        <IconTravelingTogether />
                      </div>
                      <div className="luggage-icons-icon baggage">
                        <IconBaggage />
                      </div>
                      <div className="luggage-icons-icon partners">
                        <IconPartners />
                      </div>
                      <div className="luggage-icons-icon email">
                        <IconEmail />
                      </div>
                      <div className="luggage-icons-icon save">
                        <IconSave />
                      </div>
                      <div className="luggage-icons-icon deals">
                        <IconDeals />
                      </div>

                      {/* Line four */}
                      <div className="luggage-icons-icon gift">
                        <IconGift />
                      </div>
                      <div className="luggage-icons-icon turkey">
                        <IconTurkey />
                      </div>
                      <div className="luggage-icons-icon candles">
                        <IconCandles />
                      </div>
                      <div className="luggage-icons-icon see">
                        <IconSee />
                      </div>
                      <div className="luggage-icons-icon eat">
                        <IconEat />
                      </div>
                      <div className="luggage-icons-icon mint">
                        <IconMint />
                      </div>
                      <div className="luggage-icons-icon arrow">
                        <IconArrows />
                      </div>
                      <div className="luggage-icons-icon badges">
                        <IconBadges />
                      </div>
                      <div className="luggage-icons-icon crop">
                        <IconCrop />
                      </div>

                      {/* Line five */}
                      <div className="luggage-icons-icon destinations">
                        <IconDestinations />
                      </div>
                      <div className="luggage-icons-icon question">
                        <IconQuestion />
                      </div>
                      <div className="luggage-icons-icon start">
                        <IconStar />
                      </div>
                      <div className="luggage-icons-icon close">
                        <IconClose />
                      </div>
                      <div className="luggage-icons-icon map">
                        <IconMap />
                      </div>
                      <div className="luggage-icons-icon redemption">
                        <IconRedemption />
                      </div>
                      <div className="luggage-icons-icon accruel">
                        <IconAccruel />
                      </div>
                      <div className="luggage-icons-icon alert">
                        <IconAlert />
                      </div>
                      <div className="luggage-icons-icon customerservice">
                        <IconCustomerService />
                      </div>
                      <p className="luggage-icon-copy">
                        Built from the same geometric playfulness as the tail
                        fin patterns, the icons were streamlined for clarity,
                        purpose and scalability.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

ColorSmear.contextType = JetBlueContext;
export default ColorSmear;
