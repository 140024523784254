import React from "react";
import { JetBlueContext } from "../JetBlueProvider";

import dealsDesktop from "../assets/desktop_tracker.gif";

const WebsiteSamples = () => {
  return (
    <section className="websites">
      <div className="content-container">
        <div className="row">
          <div className="column small-12 medium-1 spacer" />
          <div className="column small-12 medium-10">
            <img className="websites--desktop" src={dealsDesktop} alt="" />
          </div>
          <div className="column small-12 medium-1 spacer" />
        </div>
      </div>
    </section>
  );
};

WebsiteSamples.contextType = JetBlueContext;
export default WebsiteSamples;
