import React, { Component } from "react";
import { JetBlueContext } from "../JetBlueProvider";

import instagramPostHeader from "../assets/instagram_post_header.jpg";
import instagramPostNewYork from "../assets/instagram_post_newyork.jpg";
import instagramPostPhoenix from "../assets/instagram_post_phoenix.jpg";
import instagramPostGuayaguil from "../assets/instagram_post_guayaquil.jpg";
import instagramPostSantafe from "../assets/instagram_post_santafe.jpg";
import instagramPostCartagena from "../assets/instagram_post_catagena.jpg";
import instagramPostBurlington from "../assets/instagram_post_burlington.jpg";
import instaVideoOne from "../assets/insta_video_one.mp4";
import instaVideoTwo from "../assets/insta_video_two.mp4";
import instaVideoThree from "../assets/insta_video_three.mp4";
import instaVideoFour from "../assets/insta_video_four.mp4";
import instaVideoFive from "../assets/insta_video_five.mp4";

const MasonrySection = () => {
  return (
    <>
      <section className="new-feed">
        <div className="content-container">
          <div className="row new-feed--wrapper">
            <div className="column small-12 medium-1 spacer" />
            <div className="column small-12 medium-4">
              <h2 className="new-feed--header">
                Dreaming of Your Next Vacation??
              </h2>
            </div>
            <div className="column small-12 medium-1 spacer" />
            <div className="column small-12 medium-5">
              <p className="new-feed--desc">
                A whopping 75% of the time spent planning a vacation is in the
                “dreaming” phase. So let’s give people places to dream about in
                our feed.
              </p>
            </div>
            <div className="column small-12 medium-1 spacer" />
          </div>

          <div className="row new-feed--masonry">
            <div className="column small-12 medium-3 new-feed--set1">
              <div className="new-feed--post">
                <img
                  className="new-feed--post-header"
                  src={instagramPostHeader}
                  alt="jb-blue-logo"
                />
                <video className="new-feed--post-video" autoPlay loop>
                  <source src={instaVideoOne} type="video/mp4" />
                </video>
              </div>
              <img
                className="new-feed--post"
                src={instagramPostSantafe}
                alt="jb-blue-logo"
              />
            </div>
            <div className="column small-12 medium-3 new-feed--set2">
              <img
                className="new-feed--post"
                src={instagramPostNewYork}
                alt="jb-blue-logo"
              />
              <img
                className="new-feed--post"
                src={instagramPostGuayaguil}
                alt="jb-blue-logo"
              />
              <div className="new-feed--post">
                <img
                  className="new-feed--post-header"
                  src={instagramPostHeader}
                  alt="jb-blue-logo"
                />
                <video className="new-feed--post-video" autoPlay loop>
                  <source src={instaVideoFour} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="column small-12 medium-3 new-feed--set3">
              <div className="new-feed--post">
                <img
                  className="new-feed--post-header"
                  src={instagramPostHeader}
                  alt="jb-blue-logo"
                />
                <video className="new-feed--post-video" autoPlay loop>
                  <source src={instaVideoTwo} type="video/mp4" />
                </video>
              </div>
              <img
                className="new-feed--post"
                src={instagramPostCartagena}
                alt="jb-blue-logo"
              />
              <div className="new-feed--post">
                <img
                  className="new-feed--post-header"
                  src={instagramPostHeader}
                  alt="jb-blue-logo"
                />
                <video className="new-feed--post-video" autoPlay loop>
                  <source src={instaVideoFive} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="column small-12 medium-3 new-feed--set4">
              <img
                className="new-feed--post"
                src={instagramPostPhoenix}
                alt="jb-blue-logo"
              />
              <div className="new-feed--post">
                <img
                  className="new-feed--post-header"
                  src={instagramPostHeader}
                  alt="jb-blue-logo"
                />
                <video className="new-feed--post-video" autoPlay loop>
                  <source src={instaVideoThree} type="video/mp4" />
                </video>
              </div>
              <img
                className="new-feed--post"
                src={instagramPostBurlington}
                alt="jb-blue-logo"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

// class MasonrySection extends Component {
//   render() {
//     return (
//       <>
//         <section className="new-feed">
//           <div className="content-container">
//             <div className="row new-feed--wrapper">
//               <div className="column small-12 medium-1 spacer" />
//               <div className="column small-12 medium-4">
//                 <h2 className="new-feed--header">
//                   Dreaming of Your Next Vacation??
//                 </h2>
//               </div>
//               <div className="column small-12 medium-1 spacer" />
//               <div className="column small-12 medium-5">
//                 <p className="new-feed--desc">
//                   A whopping 75% of the time spent planning a vacation is in the
//                   “dreaming” phase. So let’s give people places to dream about
//                   in our feed.
//                 </p>
//               </div>
//               <div className="column small-12 medium-1 spacer" />
//             </div>

//             <div className="row new-feed--masonry">
//               <div className="column small-12 medium-3 new-feed--set1">
//                 <div className="new-feed--post">
//                   <img
//                     className="new-feed--post-header"
//                     src={instagramPostHeader}
//                     alt="jb-blue-logo"
//                   />
//                   <video className="new-feed--post-video" autoPlay loop>
//                     <source src={instaVideoOne} type="video/mp4" />
//                   </video>
//                 </div>
//                 <img
//                   className="new-feed--post"
//                   src={instagramPostSantafe}
//                   alt="jb-blue-logo"
//                 />
//               </div>
//               <div className="column small-12 medium-3 new-feed--set2">
//                 <img
//                   className="new-feed--post"
//                   src={instagramPostNewYork}
//                   alt="jb-blue-logo"
//                 />
//                 <img
//                   className="new-feed--post"
//                   src={instagramPostGuayaguil}
//                   alt="jb-blue-logo"
//                 />
//                 <div className="new-feed--post">
//                   <img
//                     className="new-feed--post-header"
//                     src={instagramPostHeader}
//                     alt="jb-blue-logo"
//                   />
//                   <video className="new-feed--post-video" autoPlay loop>
//                     <source src={instaVideoFour} type="video/mp4" />
//                   </video>
//                 </div>
//               </div>
//               <div className="column small-12 medium-3 new-feed--set3">
//                 <div className="new-feed--post">
//                   <img
//                     className="new-feed--post-header"
//                     src={instagramPostHeader}
//                     alt="jb-blue-logo"
//                   />
//                   <video className="new-feed--post-video" autoPlay loop>
//                     <source src={instaVideoTwo} type="video/mp4" />
//                   </video>
//                 </div>
//                 <img
//                   className="new-feed--post"
//                   src={instagramPostCartagena}
//                   alt="jb-blue-logo"
//                 />
//                 <div className="new-feed--post">
//                   <img
//                     className="new-feed--post-header"
//                     src={instagramPostHeader}
//                     alt="jb-blue-logo"
//                   />
//                   <video className="new-feed--post-video" autoPlay loop>
//                     <source src={instaVideoFive} type="video/mp4" />
//                   </video>
//                 </div>
//               </div>
//               <div className="column small-12 medium-3 new-feed--set4">
//                 <img
//                   className="new-feed--post"
//                   src={instagramPostPhoenix}
//                   alt="jb-blue-logo"
//                 />
//                 <div className="new-feed--post">
//                   <img
//                     className="new-feed--post-header"
//                     src={instagramPostHeader}
//                     alt="jb-blue-logo"
//                   />
//                   <video className="new-feed--post-video" autoPlay loop>
//                     <source src={instaVideoThree} type="video/mp4" />
//                   </video>
//                 </div>
//                 <img
//                   className="new-feed--post"
//                   src={instagramPostBurlington}
//                   alt="jb-blue-logo"
//                 />
//               </div>
//             </div>
//           </div>
//         </section>
//       </>
//     );
//   }
// }

MasonrySection.contextType = JetBlueContext;
export default MasonrySection;
