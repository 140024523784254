import React, { Component } from "react";
import { JetBlueContext } from "../JetBlueProvider";

import ScrollMagic from "../components/scrollmagic"; // custom wrapper to make animation.gsap work
import { TimelineMax, Sine, TweenMax, Back, Expo, Circ } from "gsap";
import { PhoneFramed } from "../assets/dot/Phone_Blank.svg";
import IconsDOT from "../components/svgs/IconsDayOftravel";

import boardingPassShort from "../assets/dot/boarding-pass.png";
import ribbon from "../assets/dot/ribbon.png";
import iconNav from "../assets/dot/icongroup.png";
import topNav from "../assets/dot/topnav.png";
import chat from "../assets/dot/chat.png";
import arriveTime from "../assets/dot/arrivetime.png";
import arriveTimeTwo from "../assets/dot/arrivetime2.png";
import nowTime from "../assets/dot/nowtime.png";
import nowTimeTwo from "../assets/dot/nowtime2.png";
import userFlight from "../assets/dot/usercircle.png";
import boardingHeadline from "../assets/dot/boardingHead1.png";
import boardingHeadlineTwo from "../assets/dot/boardingHead2.png";
import boardingFlightNum from "../assets/dot/flightNumber.png";
import boardingTerminal from "../assets/dot/terminalNumber.png";
import boardingGate from "../assets/dot/gateNumber.png";
import boardingGroup from "../assets/dot/groupNumber.png";
import boardingSeat from "../assets/dot/seatNumber.png";

class DayOfTravel extends Component {
  constructor(props) {
    super(props);
    this.controller = new ScrollMagic.Controller();

    this.dotAnimation = null;

    this.dotTL = new TimelineMax({ paused: true });
  }

  componentDidMount() {
    let boardingPass = document.querySelector(".boarding-pass");
    let boardingPassTop = document.querySelector(".boarding-pass-long");
    let triangles = document.querySelectorAll(".triangle");
    let phoneScreen = document.querySelector(".phone-screen");
    let ribbon = document.querySelector(".ribbon");

    // TweenMax.set(ribbon, {transformOrigin: 'bottom center'});
    TweenMax.set(boardingPass, { rotationZ: 0.01 });

    this.dotTL
      // -----------------------
      // Start of phase 1
      // -----------------------
      .to(boardingPass, 0, { autoAlpha: 1, ease: Sine.easeInOut }, "+=1")
      .from(
        boardingPass,
        1,
        { scale: 1.5, top: "50%", ease: Sine.easeInOut },
        "-=1"
      )
      .from(phoneScreen, 1, { left: "+=100vw", ease: Sine.easeInOut }, "-=1")
      .to(ribbon, 1, { css: { left: "105vw" }, ease: Sine.easeInOut }, "-=1")
      .to(
        ".phone-screen-nav, .side-cover,.phone-screen-topnav",
        0.5,
        { autoAlpha: 1, ease: Sine.easeInOut },
        "-=.15"
      )

      // -----------------------
      // Start of phase 2
      // -----------------------
      .to(triangles, 1, { autoAlpha: 1, ease: Sine.easeInOut }, "+=0")
      .from(boardingPassTop, 1, { height: 234, ease: Sine.easeInOut }, "+=0")
      .to(
        boardingPassTop,
        1,
        { autoAlpha: 1, top: "-=230", ease: Sine.easeInOut },
        "-=1"
      )
      // Fade out the image of the small boarding pass and navs
      .to(
        ".phone-screen-nav,.phone-screen-topnav, .bording-pass-short",
        1,
        { autoAlpha: 0, ease: Sine.easeInOut },
        "-=1"
      )
      // Move Ribbon into place
      .to(
        ribbon,
        1.5,
        {
          css: { left: "90px", transform: "translate(0%, calc(-50% + 85px))" },
          ease: Sine.easeInOut
        },
        "-=1"
      )
      // Fade in status bar
      .to(".statusBar", 1, { autoAlpha: 1, ease: Sine.easeInOut }, "-=1.5")
      // Slide and fade in bottom icons
      .from(
        ".boarding-pass-bottom-icons",
        1,
        { left: 200, autoAlpha: 0, ease: Sine.easeInOut },
        "-=1.5"
      )
      // Fade in the ribbon checkmark and milstone icons
      .to(
        ".icon-Checkmark, .icon-milestone",
        1,
        { autoAlpha: 1, ease: Sine.easeInOut },
        "-=0.6"
      )
      .to(
        ".phone-screen-status,.boarding-pass-bottom-chat,.boarding-pass-bottom-labels,.boarding-flight-gate,.boarding-flight-terminal,.boarding-headline,.boarding-flight-num",
        0.5,
        { autoAlpha: 1, ease: Sine.easeInOut },
        "-=1"
      )
      // Change background from white to day
      .to(
        ".day-of-travel,.day-of-travel--title,.day-of-travel--point",
        1,
        { className: "+=skyone" },
        "-=1"
      )
      // Move the ribbon over to next step
      .to(
        ".ribbon-travel-line",
        0.5,
        { autoAlpha: 1, ease: Sine.easeInOut },
        "-=.5"
      )
      // Move in weather feature
      .to(".cloud1", 1, { right: -450, ease: Back.easeOut.config(0.4) }, "-=1")
      .to(
        ".cloud2",
        1,
        { right: -360, ease: Back.easeOut.config(0.5) },
        "-=1.2"
      )
      // Reveal the Header copy
      .to(
        ".boarding-headline .gradient",
        1,
        { css: { transform: "translateX(0%)" }, ease: Sine.easeInOut },
        "-=0"
      )
      .to(".icon-milestone", 1, { left: 175 }, "-=0")
      .to(".ribbon-path-s1.dotted", 1, { width: 125, autoAlpha: 0.5 }, "-=1")
      .to(
        ".ribbon-userflight, .ribbon-nowTime",
        0.5,
        { autoAlpha: 1, ease: Sine.easeInOut },
        "-=1"
      )
      .to(
        ".ribbon-arrivalTime",
        0.5,
        { autoAlpha: 1, ease: Sine.easeInOut },
        "-=0"
      )

      // -----------------------
      // Start of phase 3
      // -----------------------

      // Fade out main text
      .to(
        ".boarding-headline2",
        1,
        { autoAlpha: 1, ease: Sine.easeInOut },
        "+=0.5"
      )
      // Fade out OnTime / Terminal / Now Time / Arrival Time
      .to(
        ".icon-Ontime, .boarding-flight-terminal, .ribbon-nowTime, .ribbon-arrivalTime",
        1,
        { autoAlpha: 0, ease: Sine.easeInOut },
        "-=1"
      )
      // Fade out two of the bottom icons
      .to(
        ".icon-scheduleLyft, .icon-tripChecklist",
        1,
        { autoAlpha: 0, ease: Sine.easeInOut },
        "-=1"
      )
      // Fade in the roaming man
      .to(".icon-littledood", 1, { autoAlpha: 1, ease: Sine.easeInOut }, "-=1")
      // Move Ribbon over
      .to(
        ".ribbon-image,.ribbon-travel-line",
        2,
        { left: "-=140px", ease: Sine.easeInOut },
        "-=1"
      )
      // Move in weather feature
      .to(".cloud1", 1, { right: -120, ease: Back.easeOut.config(0.5) }, "-=1")
      .to(".cloud2", 1, { right: -190, ease: Back.easeOut.config(0.4) }, "-=1")
      .to(".cloud3", 1, { right: -300, ease: Back.easeOut.config(0.6) }, "-=1")
      .to(".cloud4", 1, { right: -340, ease: Back.easeOut.config(0.2) }, "-=1")
      .to(".cloud5", 1, { right: -450, ease: Back.easeOut.config(0.3) }, "-=1")
      // Move checkmark over
      .to(".icon-Checkmark", 2, { left: -118, ease: Sine.easeInOut }, "-=2")
      // Move over the Milestone
      .to(".icon-milestone", 2, { left: 85, ease: Sine.easeInOut }, "-=2")
      // Change from dotted line to solid and move it over
      .to(
        ".ribbon-path-s1",
        2,
        { left: -100, width: 130, ease: Sine.easeInOut },
        "-=2"
      )
      .to(
        ".ribbon-path-s1.dotted",
        1,
        { autoAlpha: 0, ease: Sine.easeInOut },
        "-=2"
      )
      .to(
        ".ribbon-path-s1.line",
        1,
        { autoAlpha: 0.5, ease: Sine.easeInOut },
        "-=2"
      )
      // Start moving the Gate over to left
      .to(
        ".boarding-flight-gate",
        2,
        { left: "13px", ease: Sine.easeInOut },
        "-=2"
      )
      // Change status circle from green to orange
      .to(
        ".statusCircle",
        1,
        { css: { backgroundColor: "#FF8200" }, ease: Sine.easeInOut },
        "-=2"
      )
      // Fade in Delayed / Flight Group / Seat
      .to(
        ".icon-Delayed, .boarding-flight-group, .boarding-flight-seat, .icon-newTimeIcon, .icon-newNowIcon",
        1,
        { autoAlpha: 1, ease: Sine.easeInOut },
        "-=0.5"
      )
      // Change sky color
      .to(
        ".day-of-travel",
        1,
        { className: "+=skytwo", ease: Sine.easeInOut },
        "-=1"
      )
      // Fade in main text 2
      .to(
        ".boarding-headline2 .gradient",
        1,
        { css: { transform: "translateX(0%)" }, ease: Sine.easeInOut },
        "-=1"
      )
      // Fade in two bottom icons
      .to(
        ".icon-startWatching, .icon-boardingPassIcon",
        1,
        { autoAlpha: 1, ease: Sine.easeInOut },
        "-=1"
      )
      // Move over bottom chat icon
      .to(
        ".boarding-pass-bottom-chat",
        1,
        {
          css: { left: "7px", transform: "translateX(0%)", width: "264px" },
          ease: Sine.easeInOut
        },
        "-=1"
      )
      // Fade in chat copy
      .from(
        ".icon-chatCopy",
        1,
        { css: { autoAlpha: 0 }, ease: Sine.easeInOut },
        "-=0.5"
      )
      // Extend the dotted line for step 2
      .to(".ribbon-path-s2", 1, { width: 50, autoAlpha: 0.5 }, "-=1")
      // Make little plane on ribbon fly out
      .from(
        ".icon-littlePlane",
        2,
        { css: { opacity: 0, top: 100, left: 0 }, ease: Sine.easeInOut },
        "-=1"
      )
      // Move mover weather feature
      .to(".cloud1", 1.5, { right: -40, ease: Back.easeOut.config(0.2) }, "-=1")
      .to(
        ".cloud2",
        1.2,
        { right: -105, ease: Back.easeOut.config(0.4) },
        "-=1.5"
      )
      .to(
        ".cloud3",
        1.5,
        { right: -165, ease: Back.easeOut.config(0.7) },
        "-=1.5"
      )
      .to(
        ".cloud4",
        1,
        { right: -260, ease: Back.easeOut.config(0.2) },
        "-=1.5"
      )
      .to(
        ".cloud5",
        1.5,
        { right: -320, ease: Back.easeOut.config(0.7) },
        "-=1.5"
      )

      // -----------------------
      // Start of phase 4
      // -----------------------

      // Change background from duck to night
      .to(
        ".day-of-travel",
        1,
        { className: "+=skythree", ease: Sine.easeInOut },
        "+=0.5"
      )
      // Change phone screen color
      .to(
        ".phone-screen-color",
        1,
        { css: { backgroundColor: "#0033A0" }, ease: Sine.easeInOut },
        "+=0"
      )
      // Fade out current boarding pass / Bottom Nav
      .to(
        ".boarding-pass-long, .icon-manageTrip, .icon-startWatching, .icon-boardingPassIcon, .icon-newNowIcon, .icon-newTimeIcon, .icon-Checkmark, .icon-littledood, .ribbon-path-s1, .ribbon-path-s2, .icon-milestone, .icon-chatCopy, .icon-Delayed",
        1,
        { autoAlpha: 0, ease: Sine.easeInOut },
        "-=1"
      )
      // BG color of the whole section is white so lets just set that to alpha 0
      .to(
        ".boarding-pass",
        1,
        { backgroundColor: "rgba(246,246,246,0)", ease: Sine.easeInOut },
        "-=1"
      )
      // Reset chat icon bubble
      .to(
        ".boarding-pass-bottom-chat",
        1,
        {
          css: { left: 230, width: 45 },
          ease: Sine.easeInOut
        },
        "-=0.5"
      )
      // Move Ribbon over to next step
      .to(".ribbon-image", 2, { left: -470, ease: Sine.easeInOut }, "-=1")
      // Move little plane up
      .to(
        ".icon-littlePlane",
        1,
        {
          left: 20,
          top: 18,
          width: 23,
          height: 14,
          transform: "rotate(19deg)",
          ease: Sine.easeInOut
        },
        "-=1.5"
      )
      // Move mover weather feature
      .to(
        ".cloud1",
        1,
        {
          right: 220,
          autoAlpha: 0.8,
          width: 50,
          top: 410,
          ease: Circ.easeOut
        },
        "-=1"
      )
      .to(
        ".cloud2",
        1,
        {
          right: 430,
          autoAlpha: 0.4,
          width: 80,
          height: 50,
          top: 330,
          ease: Circ.easeOut
        },
        "-=1"
      )
      .to(
        ".cloud3",
        1,
        {
          right: -55,
          top: 390,
          ease: Circ.easeOut
        },
        "-=1"
      )
      .to(
        ".cloud4",
        1,
        {
          right: -200,
          ease: Circ.easeOut
        },
        "-=1"
      )
      .to(
        ".cloud5",
        1,
        {
          right: -130,
          ease: Circ.easeOut
        },
        "-=1"
      )
      // Move up user icon
      .to(
        ".ribbon-userflight",
        1,
        { bottom: 80, ease: Sine.easeInOut },
        "-=1.5"
      )
      // Fade in darkmode pass and solid line
      .to(
        ".boarding-pass-darkMode, .ribbon-path-s3.line, .icon-moon, .icon-star",
        1,
        { autoAlpha: 1, ease: Sine.easeInOut },
        "-=1"
      )
      // Fade in and expand the dotted line
      .from(".ribbon-path-s3.dotted", 1, { autoAlpha: 0, width: 0 }, "-=0")
      // Change status icon from orange to green
      .to(
        ".statusCircle",
        1,
        { css: { backgroundColor: "#6cc24a" }, ease: Sine.easeInOut },
        "-=1"
      )
      // Fade in onTime text
      .to(".icon-Ontime", 1, { autoAlpha: 1, ease: Sine.easeInOut }, "-=1")

      // -----------------------
      // Start of phase 5 - About to land
      // -----------------------

      // Fade out main text / now time / bottom copy and button
      .to(
        ".icon-landTimeLong, .icon-connectBtn, .icon-landTimeMid, .icon-wifiText",
        1,
        { autoAlpha: 0, ease: Sine.easeInOut },
        "+=0.5"
      )
      // Move Ribbon over to next step
      .to(".ribbon-image", 2, { left: -555, ease: Sine.easeInOut }, "-=1")
      // Fade out moon and stars and move over the clouds
      .to(
        ".icon-moon, .icon-star",
        1,
        { autoAlpha: 0, left: "-=5vw", ease: Sine.easeInOut },
        "-=1"
      )
      .to(".cloud1", 1, { right: 415, ease: Sine.easeInOut }, "-=1")
      .to(".cloud2", 1, { right: 630, ease: Sine.easeInOut }, "-=1")
      .to(".cloud3, .cloud4", 1, { right: 100, ease: Sine.easeInOut }, "-=1")
      .to(".cloud5", 1, { right: 300, ease: Sine.easeInOut }, "-=1")
      // Move over dotted and solid travel lines
      .to(
        ".ribbon-path-s3.line",
        2,
        { left: -176, width: 193, ease: Sine.easeInOut },
        "-=2"
      )
      .to(
        ".ribbon-path-s3.dotted",
        2,
        { width: 56, ease: Sine.easeInOut },
        "-=2"
      )
      // Fade in Now Arriving time / Main copy
      .to(
        ".icon-NowTimeLanding, .icon-landTextLarge",
        1,
        { autoAlpha: 1, ease: Sine.easeInOut },
        "-=1"
      )
      // Change background from night to dawn
      .to(
        ".day-of-travel",
        1,
        { className: "+=skyfour", ease: Sine.easeInOut },
        "-=2"
      )
      // Roll in the dots on timeline from right for Baggage claim and etc
      .from(
        ".icon-whiteRing, .icon-baggageClaim, .icon-lyftService, .icon-arriveatHome",
        1,
        { autoAlpha: 0, left: 800, ease: Sine.easeInOut },
        "-=2"
      )
      // Show orange pointer line
      .to(".icon-landingLine", 1, { autoAlpha: 1, ease: Sine.easeInOut }, "-=1")
      // Extend the dotted arrival line under
      .from(
        ".ribbon-path-s4.dotted",
        2,
        { autoAlpha: 0, width: 0, ease: Sine.easeInOut },
        "-=1"
      )
      // Fade in the bottom icons
      .to(
        ".icon-continueWatching, .icon-snackDrinks, .icon-flightMap",
        1,
        { autoAlpha: 1, ease: Sine.easeInOut },
        "-=1"
      )
      // Move the clouds over a bit more
      .to(".cloud2", 1, { right: 680, ease: Sine.easeInOut }, "-=1")
      .to(".cloud5", 1, { right: 350, ease: Sine.easeInOut }, "-=1")

      // -----------------------
      // Start of phase 6 - Landed
      // -----------------------
      // Change background from dawn to day
      .to(
        ".day-of-travel",
        1,
        { className: "+=skyfive", ease: Sine.easeInOut },
        "+=0.5"
      )
      // Move the clouds
      .to(
        ".cloud2,.cloud5, .cloud1",
        1,
        { right: "50vw", autoAlpha: 0, ease: Sine.easeInOut },
        "-=1"
      )
      .from(
        ".cloud6,.cloud7,.cloud8",
        1,
        { right: "-50vw", ease: Sine.easeInOut },
        "-=1"
      )
      .from(
        ".icon-sun",
        1,
        { top: "100vw", ease: Back.easeOut.config(0.8) },
        "-=1"
      )
      // Move Ribbon over to next step
      .to(".ribbon-image", 2, { left: -780, ease: Sine.easeInOut }, "-=1")
      // Fading out Main text / Now time / User Icon / Bottom Icons / Orange Line / Plane and dotted line
      .to(
        ".icon-continueWatching, .icon-snackDrinks, .icon-flightMap, .icon-landTextLarge, .ribbon-userflight, .icon-NowTimeLanding, .ribbon-path-s3, .icon-littlePlane, .icon-landingLine, .icon-baggageClaim, .icon-whiteRing.arriveatHome",
        1,
        { autoAlpha: 0, ease: Sine.easeInOut },
        "-=2"
      )
      // Move over the dotted line
      .to(
        ".ribbon-path-s4.dotted",
        2,
        { left: 235, width: 345, ease: Sine.easeInOut },
        "-=2"
      )
      // Move over outside time points
      .to(".icon-lyftService", 1, { left: 425, ease: Sine.easeInOut }, "-=1")
      .to(".icon-arriveatHome", 1, { left: 540, ease: Sine.easeInOut }, "-=1")
      // Fade in Landed Text / Rating / Points / Person Icon
      .to(
        ".icon-welcomeTo, .icon-pointsEarned, .icon-rating, .icon-TimeNowLast, .icon-BaggageClaimWhite, .icon-littledood",
        2,
        { autoAlpha: 1, ease: Sine.easeInOut },
        "-=1"
      )
      // Fade in and move over the Milestone icon
      .to(
        ".icon-milestone",
        1.5,
        { left: 225, autoAlpha: 1, ease: Back.easeOut.config(1.4) },
        "-=1.5"
      )
      // Clapping Animation
      .from(
        ".handWave",
        1,
        { bottom: 100, autoAlpha: 0, ease: Expo.easeOut },
        "-=0"
      )
      .from(
        ".icon-clapsNumber",
        1,
        {
          autoAlpha: 0,
          scale: 0,
          ease: Back.easeOut.config(1.4)
        },
        "-=1"
      )
      .from(
        ".handR",
        0.1,
        {
          transform: "rotate(10deg)",
          repeat: 10,
          yoyo: true,
          ease: Expo.easeOut
        },
        "-=1"
      )
      .from(
        ".handL",
        0.1,
        {
          transform: "rotate(-8deg)",
          repeat: 10,
          yoyo: true,
          ease: Expo.easeOut
        },
        "-=1.1"
      )
      .to(
        ".icon-clapEffect",
        0.1,
        {
          autoAlpha: 0,
          repeat: 11,
          yoyo: true,
          ease: Expo.easeOut
        },
        "-=1"
      )
      // Change rating icons from none to filled
      .staggerTo(
        ".icon-ratingFilled",
        0.5,
        { opacity: 1, ease: Back.easeIn },
        "-=1"
      )
      // Fade in replay button
      .to(
        ".day-of-travel--replay",
        1,
        {
          autoAlpha: 1,
          ease: Expo.easeOut
        },
        "-=0"
      );

    // -----------------------
    // End of Animation
    // -----------------------

    const that = this;
    this.DOTAnimation = new ScrollMagic.Scene({
      triggerElement: ".day-of-travel",
      triggerHook: "onCenter",
      duration: "100%",
      reverse: true
    })
      .on("enter", function(e) {
        that.dotTL.play();
      })
      .on("leave", function(e) {
        that.dotTL.pause();
      });
    this.controller.addScene([this.DOTAnimation]);
  }

  replayAnimation = e => {
    e.preventDefault();
    this.dotTL.restart();
  };

  render() {
    return (
      <section className="day-of-travel">
        <IconsDOT />
        <div className="content-container dot-header">
          <div className="row">
            <div className="column small-12 medium-12">
              <h2 className="day-of-travel--title">
                Reimagining
                <br />
                The Day of Travel
              </h2>
            </div>
          </div>
        </div>
        <div className="day-of-travel--animation-holder">
          <div className="phone">
            <div className="boarding-pass-bottom-icons">
              <svg className="icon icon-manageTrip">
                <use xlinkHref="#icon-manageTrip" />
              </svg>
              <svg className="icon icon-scheduleLyft">
                <use xlinkHref="#icon-scheduleLyft" />
              </svg>
              <svg className="icon icon-tripChecklist">
                <use xlinkHref="#icon-tripChecklist" />
              </svg>
              <svg className="icon icon-startWatching">
                <use xlinkHref="#icon-startWatching" />
              </svg>
              <svg className="icon icon-boardingPassIcon">
                <use xlinkHref="#icon-boardingPassIcon" />
              </svg>
              <svg className="icon icon-continueWatching">
                <use xlinkHref="#icon-continueWatching" />
              </svg>
              <svg className="icon icon-snackDrinks">
                <use xlinkHref="#icon-snackDrinks" />
              </svg>
              <svg className="icon icon-flightMap">
                <use xlinkHref="#icon-flightMap" />
              </svg>
            </div>
            <div className="boarding-pass">
              <img
                className="bording-pass-short"
                src={boardingPassShort}
                alt=""
              />
              <div className="boarding-pass-long">
                <img
                  className="boarding-flight-num"
                  src={boardingFlightNum}
                  alt="flight number"
                />
                <div className="boarding-headline">
                  <img className="head" src={boardingHeadline} alt="headline" />
                  <div className="gradient" />
                </div>

                <div className="boarding-headline2">
                  <img
                    className="head"
                    src={boardingHeadlineTwo}
                    alt="headline2"
                  />
                  <div className="gradient" />
                </div>

                <img
                  className="boarding-flight-terminal"
                  src={boardingTerminal}
                  alt="boarding terminal"
                />
                <img
                  className="boarding-flight-gate"
                  src={boardingGate}
                  alt="boarding gate"
                />
                <img
                  className="boarding-flight-group"
                  src={boardingGroup}
                  alt="boarding group"
                />
                <img
                  className="boarding-flight-seat"
                  src={boardingSeat}
                  alt="boarding seat"
                />

                <div className="triangle triangle-left" />
                <div className="triangle triangle-right" />
                <div className="top-half" />
              </div>
              <div className="boarding-pass-darkMode">
                <svg className="icon icon-FlightNoDark">
                  <use xlinkHref="#icon-FlightNoDark" />
                </svg>
                <svg className="icon icon-downChevron">
                  <use xlinkHref="#icon-downChevron" />
                </svg>
                <svg className="icon icon-landTimeLong">
                  <use xlinkHref="#icon-landTimeLong" />
                </svg>
                <svg className="icon icon-connectBtn">
                  <use xlinkHref="#icon-connectBtn" />
                </svg>
                <svg className="icon icon-landTimeMid">
                  <use xlinkHref="#icon-landTimeMid" />
                </svg>
                <svg className="icon icon-wifiText">
                  <use xlinkHref="#icon-wifiText" />
                </svg>
                <svg className="icon icon-landTextLarge">
                  <use xlinkHref="#icon-landTextLarge" />
                </svg>
                <svg className="icon icon-welcomeTo">
                  <use xlinkHref="#icon-welcomeTo" />
                </svg>
                <svg className="icon icon-pointsEarned">
                  <use xlinkHref="#icon-pointsEarned" />
                </svg>
                <svg className="icon icon-rating">
                  <use xlinkHref="#icon-rating" />
                </svg>
                <svg className="icon icon-ratingFilled filled1">
                  <use xlinkHref="#icon-ratingFilled" />
                </svg>
                <svg className="icon icon-ratingFilled filled2">
                  <use xlinkHref="#icon-ratingFilled" />
                </svg>
                <svg className="icon icon-ratingFilled filled3">
                  <use xlinkHref="#icon-ratingFilled" />
                </svg>
                <svg className="icon icon-ratingFilled filled4">
                  <use xlinkHref="#icon-ratingFilled" />
                </svg>
                <svg className="icon icon-ratingFilled filled5">
                  <use xlinkHref="#icon-ratingFilled" />
                </svg>
                <div className="handWave">
                  <svg className="icon icon-handWave handL">
                    <use xlinkHref="#icon-handWave" />
                  </svg>
                  <svg className="icon icon-handWave handR">
                    <use xlinkHref="#icon-handWave" />
                  </svg>
                  <svg className="icon icon-clapsNumber">
                    <use xlinkHref="#icon-clapsNumber" />
                  </svg>
                  <svg className="icon icon-clapEffect">
                    <use xlinkHref="#icon-clapEffect" />
                  </svg>
                </div>
              </div>
              <div className="boarding-pass-bottom-chat">
                <img src={chat} alt="chat" />
                <svg className="icon icon-chatCopy">
                  <use xlinkHref="#icon-chatCopy" />
                </svg>
              </div>
            </div>
            <div className="phone-screen">
              <PhoneFramed className="phoneFrame" />
              <div className="statusBar">
                <svg className="icon icon-airportCodes">
                  <use xlinkHref="#icon-airportCodes" />
                </svg>
                <span className="statusCircle" />
                <div className="statusText">
                  <svg className="icon icon-Ontime">
                    <use xlinkHref="#icon-Ontime" />
                  </svg>
                  <svg className="icon icon-Taxiingtogate">
                    <use xlinkHref="#icon-Taxiingtogate" />
                  </svg>
                  <svg className="icon icon-Delayed">
                    <use xlinkHref="#icon-Delayed" />
                  </svg>
                  <svg className="icon icon-Baggageisready">
                    <use xlinkHref="#icon-Baggageisready" />
                  </svg>
                </div>
              </div>
              <div className="phone-screen-color" />
              <img className="phone-screen-topnav" src={topNav} />
              <img className="phone-screen-nav" src={iconNav} />
            </div>
            <div className="phone-screen side-cover">
              <div className="bar" />
            </div>
            <div className="ribbon">
              <img className="ribbon-image" src={ribbon} alt="ribbon" />
              <img
                className="ribbon-arrivalTime"
                src={arriveTime}
                alt="arrival time"
              />
              <img
                className="ribbon-arrivalTime2"
                src={arriveTimeTwo}
                alt="arrival time 2"
              />
              <img className="ribbon-nowTime" src={nowTime} alt="now time" />
              <img
                className="ribbon-nowTime2"
                src={nowTimeTwo}
                alt="now time 2"
              />
              <img
                className="ribbon-userflight"
                src={userFlight}
                alt="user flight"
              />
              <svg className="icon icon-newNowIcon">
                <use xlinkHref="#icon-newNowIcon" />
              </svg>
              <svg className="icon icon-newTimeIcon">
                <use xlinkHref="#icon-newTimeIcon" />
              </svg>
              <svg className="icon icon-NowTimeLanding">
                <use xlinkHref="#icon-NowTimeLanding" />
              </svg>
              <svg className="icon icon-Checkmark">
                <use xlinkHref="#icon-Checkmark" />
              </svg>
              <svg className="icon icon-littledood">
                <use xlinkHref="#icon-littledood" />
              </svg>
              <span className="ribbon-path-s1 dotted" />
              <span className="ribbon-path-s1 line" />
              <span className="ribbon-path-s2" />
              <span className="ribbon-path-s3 line" />
              <span className="ribbon-path-s3 dotted" />
              <span className="ribbon-path-s4 dotted" />
              <svg className="icon icon-milestone">
                <use xlinkHref="#icon-milestone" />
              </svg>
              <svg className="icon icon-littlePlane">
                <use xlinkHref="#icon-littlePlane" />
              </svg>
              <svg className="icon icon-landingLine">
                <use xlinkHref="#icon-Line" />
              </svg>
              <svg className="icon icon-whiteRing baggageClaim">
                <use xlinkHref="#icon-whiteRing" />
              </svg>
              <svg className="icon icon-baggageClaim">
                <use xlinkHref="#icon-baggageClaim" />
              </svg>
              <svg className="icon icon-whiteRing lyftService">
                <use xlinkHref="#icon-whiteRing" />
              </svg>
              <svg className="icon icon-lyftService">
                <use xlinkHref="#icon-lyftService" />
              </svg>
              <svg className="icon icon-whiteRing arriveatHome">
                <use xlinkHref="#icon-whiteRing" />
              </svg>
              <svg className="icon icon-arriveatHome">
                <use xlinkHref="#icon-arriveatHome" />
              </svg>
              <svg className="icon icon-arriveatHome">
                <use xlinkHref="#icon-arriveatHome" />
              </svg>
              <svg className="icon icon-TimeNowLast">
                <use xlinkHref="#icon-TimeNowLast" />
              </svg>
              <svg className="icon icon-BaggageClaimWhite">
                <use xlinkHref="#icon-BaggageClaimWhite" />
              </svg>
            </div>
            <div className="weather">
              <svg className="icon icon-cloudIcon cloud1">
                <use xlinkHref="#icon-cloudIcon" />
              </svg>
              <svg className="icon icon-cloud-stroke cloud2">
                <use xlinkHref="#icon-cloud-stroke" />
              </svg>
              <svg className="icon icon-cloudIcon cloud3">
                <use xlinkHref="#icon-cloudIcon" />
              </svg>
              <svg className="icon icon-cloudIcon cloud4">
                <use xlinkHref="#icon-cloudIcon" />
              </svg>
              <svg className="icon icon-cloudIcon cloud5">
                <use xlinkHref="#icon-cloudIcon" />
              </svg>
              <svg className="icon icon-cloudIcon cloud6">
                <use xlinkHref="#icon-cloudIcon" />
              </svg>
              <svg className="icon icon-cloudIcon cloud7">
                <use xlinkHref="#icon-cloudIcon" />
              </svg>
              <svg className="icon icon-cloudIcon cloud8">
                <use xlinkHref="#icon-cloudIcon" />
              </svg>
              <svg className="icon icon-moon">
                <use xlinkHref="#icon-moon" />
              </svg>
              <svg className="icon icon-sun">
                <use xlinkHref="#icon-sun" />
              </svg>

              <svg className="icon icon-star star1">
                <use xlinkHref="#icon-star" />
              </svg>
              <svg className="icon icon-star star2">
                <use xlinkHref="#icon-star" />
              </svg>
              <svg className="icon icon-star star3">
                <use xlinkHref="#icon-star" />
              </svg>
              <svg className="icon icon-star star4">
                <use xlinkHref="#icon-star" />
              </svg>
              <svg className="icon icon-star star5">
                <use xlinkHref="#icon-star" />
              </svg>
              <svg className="icon icon-star star6">
                <use xlinkHref="#icon-star" />
              </svg>
              <svg className="icon icon-star star7">
                <use xlinkHref="#icon-star" />
              </svg>
              <svg className="icon icon-star star8">
                <use xlinkHref="#icon-star" />
              </svg>
              <svg className="icon icon-star star9">
                <use xlinkHref="#icon-star" />
              </svg>
              <svg className="icon icon-star star10">
                <use xlinkHref="#icon-star" />
              </svg>
              <svg className="icon icon-star star11">
                <use xlinkHref="#icon-star" />
              </svg>
              <svg className="icon icon-star star12">
                <use xlinkHref="#icon-star" />
              </svg>
              <svg className="icon icon-star star13">
                <use xlinkHref="#icon-star" />
              </svg>
            </div>
            <button
              className="day-of-travel--replay"
              onClick={this.replayAnimation}
            >
              Replay Animation
            </button>
          </div>
        </div>
      </section>
    );
  }
}

DayOfTravel.contextType = JetBlueContext;
export default DayOfTravel;
