import React, { Component } from "react";
import { JetBlueContext } from "../JetBlueProvider";

import ScrollMagic from "../components/scrollmagic"; // custom wrapper to make animation.gsap work
import { TimelineMax, Sine } from "gsap";

class Results extends Component {
  constructor(props) {
    super(props);
    this.controller = new ScrollMagic.Controller();

    this.statSetOneAnimation = null;
    this.statSetTwoAnimation = null;

    this.statSetOneTL = new TimelineMax({ paused: false });
    this.statSetTwoTL = new TimelineMax({ paused: false });
  }

  componentDidMount() {
    let stats = {
      statOne: 0,
      statTwo: 0,
      statThree: 0,
      statFour: 0
    };
    let statOneHolder = document.querySelector(".stat-one");
    let statTwoHolder = document.querySelector(".stat-two");
    let statThreeHolder = document.querySelector(".stat-three");
    let statFourHolder = document.querySelector(".stat-four");

    this.statSetOneTL
      .to(stats, 4, {
        statOne: 70,
        onUpdate: function() {
          statOneHolder.innerHTML = `+${Math.ceil(stats.statOne)}%`;
        },
        ease: Sine.easeInOut
      })
      .to(
        stats,
        4,
        {
          statTwo: 38,
          onUpdate: function() {
            statTwoHolder.innerHTML = `+${Math.ceil(stats.statTwo)}%`;
          },
          ease: Sine.easeInOut
        },
        "-=4"
      );

    this.statSetTwoTL
      .to(stats, 4, {
        statThree: 34,
        onUpdate: function() {
          statThreeHolder.innerHTML = `+${Math.ceil(stats.statThree)}%`;
        },
        ease: Sine.easeInOut
      })
      .to(
        stats,
        4,
        {
          statFour: 15,
          onUpdate: function() {
            statFourHolder.innerHTML = `+${Math.ceil(stats.statFour)}%`;
          },
          ease: Sine.easeInOut
        },
        "-=4"
      );

    this.statSetOneAnimation = new ScrollMagic.Scene({
      triggerElement: ".results--stat-trigger-1",
      triggerHook: 1,
      duration: "60%", // scroll distance
      reverse: true
    }).setTween(this.statSetOneTL);

    this.statSetTwoAnimation = new ScrollMagic.Scene({
      triggerElement: ".results--stat-trigger-2",
      triggerHook: 1,
      duration: "60%", // scroll distance
      reverse: true
    }).setTween(this.statSetTwoTL);

    // adding via array allows to add multi scenes after all are declared.
    this.controller.addScene([
      this.statSetOneAnimation,
      this.statSetTwoAnimation
    ]);
  }

  render() {
    return (
      <>
        <section className="kind-is-good">
          <div className="content-container">
            <div className="row kind-is-good--wrapper">
              <div className="column small-12 medium-1 spacer" />
              <div className="column small-12 medium-4">
                <h2 className="kind-is-good--header">Kind is good business</h2>
              </div>
              <div className="column small-12 medium-1 spacer" />
              <div className="column small-12 medium-3">
                <p className="new-feed--desc">And our clients agree.</p>
              </div>
              <div className="column small-12 medium-1 spacer" />
            </div>

            <div className="row kind-is-good--quote">
              <div className="column small-12 medium-1 spacer" />
              <div className="column small-12 medium-10">
                <p className="quote">
                  Customers will see immediate benefits in the new design, we’re
                  truly excited by the platform we have established.
                </p>
              </div>
              <div className="column small-12 medium-1 spacer" />
            </div>

            <div className="row kind-is-good--quotename">
              <div className="column small-12 medium-6 spacer" />
              <div className="column small-12 medium-6">
                <p>
                  Maryssa Miller,
                  <br /> Head of Digital and Customer Products at JetBlue
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="results">
          <div className="content-container">
            <div className="row results--wrapper">
              <div className="column small-12 medium-1 spacer" />
              <div className="column small-12 medium-4">
                <h2 className="kind-is-good--header">The Results</h2>
              </div>
              <div className="column small-12 medium-1 spacer" />
              <div className="column small-12 medium-5">
                <p className="new-feed--desc">
                  The numbers speak for themselves. Even in the early days of
                  implementation, the new platform and design experience, is
                  helping travelers more easily find the travel solution they
                  were dreaming about.
                </p>
              </div>
              <div className="column small-12 medium-1 spacer" />
            </div>
            <div className="row results--firstrow">
              <div className="column small-12 medium-1 spacer" />
              <div className="column small-12 medium-3">
                <div className="results--stat">
                  <div className="results--stat-trigger-1" />
                  <span className="results--stat-number stat-one">+70%</span>
                  <p className="results--stat-copy">
                    Increase in mobile look-to-book.
                  </p>
                </div>
              </div>
              <div className="column small-12 medium-2 spacer" />
              <div className="column small-12 medium-3">
                <div className="results--stat">
                  <span className="results--stat-number stat-two">+38%</span>
                  <p className="results--stat-copy">
                    Increase in desktop look-to-book.
                  </p>
                </div>
              </div>
              <div className="column small-12 medium-3 spacer" />
            </div>

            <div className="row">
              <div className="column small-12 medium-1" />
              <div className="column small-12 medium-3">
                <div className="results--stat">
                  <div className="results--stat-trigger-2" />
                  <span className="results--stat-number stat-three">+34%</span>
                  <p className="results--stat-copy">
                    Increase in mobile conversion.
                  </p>
                </div>
              </div>
              <div className="column small-12 medium-2 spacer" />
              <div className="column small-12 medium-3">
                <div className="results--stat">
                  <span className="results--stat-number stat-four">+15%</span>
                  <p className="results--stat-copy">
                    Increase in desktop conversion.
                  </p>
                </div>
              </div>
              <div className="column small-12 medium-3 spacer" />
            </div>
          </div>
        </section>
      </>
    );
  }
}

Results.contextType = JetBlueContext;
export default Results;
