import React from "react";
import { JetBlueContext } from "../JetBlueProvider";
import BssLogo from "../assets/BigSpaceship_LOGO.svg";

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div className="content-container">
          <div className="row">
            <div className="column small-12 medium-1 spacer" />
            <div className="column small-12 medium-4">
              <p className="footer-subhead">GENERAL INQUIRIES</p>
              <a href="mailto:info@bigspaceship.com">info@bigspaceship.com</a>
            </div>
            <div className="column small-12 medium-3">
              <p className="footer-subhead">NEW BUSINESS</p>
              <a href="mailto:biz@bigspaceship.com">biz@bigspaceship.com</a>
            </div>

            <div className="column small-12 medium-3">
              <p className="footer-subhead">PRESS INQUIRIES</p>
              <a href="mailto:press@bigspaceship.com">press@bigspaceship.com</a>
            </div>
            <div className="column small-12 medium-1 spacer" />
          </div>

          <div className="row">
            <div className="column small-12 medium-1 spacer" />
            <div className="column small-12 medium-10 footer-divider" />
            <div className="column small-12 medium-1 spacer" />
          </div>

          <div className="row">
            <div className="column small-12 medium-1 spacer" />
            <div className="column small-12 medium-4">
              <p className="footer-subhead">(718) 222-0281</p>
              <p>
                55 Washington St. 5th Floor
                <br />
                Brooklyn, NY 11201
              </p>
            </div>
            <div className="column small-12 medium-3" />
            <div className="column small-12 medium-3">
              <p className="footer-subhead">GENERAL INQUIRIES</p>
              <a href="mailto:info@bigspaceship.com">info@bigspaceship.com</a>
            </div>
            <div className="column small-12 medium-1 spacer" />
          </div>

          <div className="footerContainer">
            <div className="footerLogoContainer">
              <div className="bss-logo-container-footer">
                <BssLogo />
              </div>
              <p className="footer-small-copy tagline">
                A MODERN PARTNER TO AMBITIOUS BRANDS
              </p>
            </div>
            <p className="footer-small-copy footer-copyright">
              ©2019 BIG SPACESHIP LLC MADE IN NY
            </p>
            <a
              className="footer-small-copy privacy-link"
              href="https://www.bigspaceship.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              PRIVACY TERMS
            </a>
          </div>
        </div>
      </footer>
    </>
  );
};

// class Footer extends Component {
//   render() {
//     return (
//       <>
//         <footer className="footer">
//           <div className="content-container">
//             <div className="row">
//               <div className="column small-12 medium-1 spacer" />
//               <div className="column small-12 medium-4">
//                 <p className="footer-subhead">GENERAL INQUIRIES</p>
//                 <a href="mailto:info@bigspaceship.com">info@bigspaceship.com</a>
//               </div>
//               <div className="column small-12 medium-3">
//                 <p className="footer-subhead">NEW BUSINESS</p>
//                 <a href="mailto:biz@bigspaceship.com">biz@bigspaceship.com</a>
//               </div>

//               <div className="column small-12 medium-3">
//                 <p className="footer-subhead">PRESS INQUIRIES</p>
//                 <a href="mailto:press@bigspaceship.com">
//                   press@bigspaceship.com
//                 </a>
//               </div>
//               <div className="column small-12 medium-1 spacer" />
//             </div>

//             <div className="row">
//               <div className="column small-12 medium-1 spacer" />
//               <div className="column small-12 medium-10 footer-divider" />
//               <div className="column small-12 medium-1 spacer" />
//             </div>

//             <div className="row">
//               <div className="column small-12 medium-1 spacer" />
//               <div className="column small-12 medium-4">
//                 <p className="footer-subhead">(718) 222-0281</p>
//                 <p>
//                   55 Washington St. 5th Floor
//                   <br />
//                   Brooklyn, NY 11201
//                 </p>
//               </div>
//               <div className="column small-12 medium-3" />
//               <div className="column small-12 medium-3">
//                 <p className="footer-subhead">GENERAL INQUIRIES</p>
//                 <a href="mailto:info@bigspaceship.com">info@bigspaceship.com</a>
//               </div>
//               <div className="column small-12 medium-1 spacer" />
//             </div>

//             <div className="footerContainer">
//               <div className="footerLogoContainer">
//                 <div className="bss-logo-container-footer">
//                   <BssLogo />
//                 </div>
//                 <p className="footer-small-copy tagline">
//                   A MODERN PARTNER TO AMBITIOUS BRANDS
//                 </p>
//               </div>
//               <p className="footer-small-copy footer-copyright">
//                 ©2019 BIG SPACESHIP LLC MADE IN NY
//               </p>
//               <a
//                 className="footer-small-copy privacy-link"
//                 href="https://www.bigspaceship.com/privacy"
//                 target="_blank"
//                 rel="noopener noreferrer"
//               >
//                 PRIVACY TERMS
//               </a>
//             </div>
//           </div>
//         </footer>
//       </>
//     );
//   }
// }

Footer.contextType = JetBlueContext;
export default Footer;
